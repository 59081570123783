const slidersOptionsArray = [
  {
    mockContainer: '.branding-mock-container',
    mockSelector: '.branding-mock',
    buttonsSelector: '.branding-card .branding-buttons .select-circle[data-branding-number]',
    imagesSelector: '.branding-card .branding-img img[data-branding-number]',
    datasetName: 'brandingNumber',
  },
  {
    mockContainer: '.mockups-container',
    mockSelector: '.posibilities-mock',
    buttonsSelector: '.possibilities_cards .possibility_card[data-possibility-number]',
    imagesSelector: '.posibilities_image .posibilities-screen[data-possibility-number]',
    datasetName: 'possibilityNumber',
  },
];

const mockupSlider = (sliders) => {
  const ACTIVE_CLASSNAME = 'active';
  const LAPTOP_CLASSNAME = 'laptop';

  const sliderInit = (mockContainer, mockSelector, buttonsSelector, imagesSelector, datasetName) => {
    const buttons = document.querySelectorAll(buttonsSelector);
    const images = Array.from(document.querySelectorAll(imagesSelector));
    const mock = document.querySelectorAll(mockSelector);
    const mockOverlay = document.querySelector(mockContainer);

    const onButtonClick = (event) => {
      const slideNumber = event.currentTarget.dataset[datasetName];

      if (!event.currentTarget.classList.contains(ACTIVE_CLASSNAME) && slideNumber) {
        const imageToShow = images.filter((element) => element.dataset[datasetName] === slideNumber);

        if (slideNumber && imageToShow.length) {
          buttons.forEach((element) => element.classList.remove(ACTIVE_CLASSNAME));
          images.forEach((element) => element.classList.remove(ACTIVE_CLASSNAME));
          mock.forEach((element) => element.classList.remove(ACTIVE_CLASSNAME));
          mockOverlay.classList.remove(LAPTOP_CLASSNAME);

          event.currentTarget.classList.add(ACTIVE_CLASSNAME);
          imageToShow.forEach((element) => {
            element.classList.add(ACTIVE_CLASSNAME);
            if (!element.classList.contains('laptop')) {
              mock.forEach((element) => element.classList.add(ACTIVE_CLASSNAME));
            } else {
              mockOverlay.classList.add(LAPTOP_CLASSNAME);
            }
          });
        }
      }
    };

    buttons.forEach((el) => {
      if (window.matchMedia('(max-width: 1024px)').matches) {
        el.addEventListener('click', onButtonClick);
        el.removeEventListener('mouseover', onButtonClick);
      } else {
        el.addEventListener('mouseover', onButtonClick);
        el.removeEventListener('click', onButtonClick);
      }
    });
  };

  sliders.forEach(({ mockContainer, mockSelector, buttonsSelector, imagesSelector, datasetName }) =>
    sliderInit(mockContainer, mockSelector, buttonsSelector, imagesSelector, datasetName),
  );
};

mockupSlider(slidersOptionsArray);
